import React, { FunctionComponent } from "react";
import { Button as MuiButton, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ButtonProps } from "./models";

const useStyles = makeStyles<Theme, Pick<ButtonProps, "shape" | "variant" | "fullWidth" | "padding">>((theme) => ({
  root: {
    borderRadius: ({ shape }) => (shape === "rounded" ? 42 : 0),
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightMedium,
    transition: "all ease-in .15s",
    whiteSpace: "nowrap",
    "&:hover": {
      boxShadow: "none",
    },
    width: ({ fullWidth }) => (fullWidth ? "100%" : "auto"),
  },
  containedPrimary: {
    border: `2px solid ${theme.palette.primary.main}`,
    "&:hover": {
      border: `2px solid ${theme.palette.info.main}`,
      backgroundColor: theme.palette.info.main,
    },
  },
  outlinedPrimary: {
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.primary.main}`,
    "&:hover": {
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  disabled: {
    color: ({ variant }) =>
      `${variant === "contained" ? theme.palette.primary.main : theme.palette.grey[400]} !important`,
    backgroundColor: ({ variant }) =>
      `${variant === "contained" ? theme.palette.grey[400] : theme.palette.common.white} !important`,
    border: `2px solid ${theme.palette.grey[400]} !important`,
  },
  sizeLarge: {
    height: 55,
    fontSize: 18,
    letterSpacing: 1.8,
    padding: theme.spacing(0, 15),
  },
  sizeMedium: {
    height: 45,
    fontSize: 16,
    letterSpacing: 1.6,
    padding: (props) => props.padding || theme.spacing(0, 12.5),
  },
  sizeSmall: {
    height: 35,
    fontSize: 14,
    letterSpacing: 1.4,
    padding: theme.spacing(0, 10),
  },
}));

const Button: FunctionComponent<ButtonProps> = ({
  children,
  shape,
  variant,
  padding,
  upload,
  component,
  testId,
  fullWidth,
  ...props
}) => {
  const classes = useStyles({ shape, variant, fullWidth, padding });

  return (
    <MuiButton
      classes={classes}
      variant={variant}
      data-testid={testId ? `button-${testId}` : "button"}
      component={upload ? "label" : component!}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

Button.defaultProps = {
  autoFocus: false,
  variant: "contained",
  shape: "sharp",
  color: "primary",
  component: "button",
};

export default Button;
