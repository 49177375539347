import { AppRoute } from "@app/models/AppRoute";
import { Permission } from "@user/models";
import { AccountSummaryReportName, ReportingNavigationSection, ReportName } from "@reporting/models";

export const getReportingNavigationLinks = (
  permissions: Record<Permission, boolean>,
  isFundAvalable = true,
  isAlpInvestData = true
): Array<ReportingNavigationSection> => {
  const result: Array<ReportingNavigationSection> = [];

  const carlyleReportingOptions = [
    {
      id: AppRoute.AccountSummaryCarlyle,
      label: AccountSummaryReportName.Single,
      isActive: permissions.ViewAccountSummaryCarlyle && isFundAvalable,
    },
    {
      id: AppRoute.CapitalAccountStatement,
      label: ReportName.CapitalAccountStatement,
      isActive: permissions.ViewReportsCarlyle && isFundAvalable,
    },
    {
      id: AppRoute.ScheduleOfInvestments,
      label: ReportName.ScheduleOfInvestments,
      isActive: permissions.ViewReportsCarlyle && isFundAvalable,
    },
    {
      id: AppRoute.CashFlowActivity,
      label: ReportName.CashFlowActivity,
      isActive: permissions.ViewReportsCarlyle,
    },
  ];

  const alpInvestReportingOptions = [
    {
      id: AppRoute.AccountSummaryAlpInvest,
      label: AccountSummaryReportName.Single,
      isActive: permissions.ViewAccountSummaryAlpInvest && isAlpInvestData,
    },
    {
      id: AppRoute.PortfolioPerformance,
      label: ReportName.PortfolioPerformance,
      isActive: permissions.ViewPortfolioPerformanceReport && isAlpInvestData,
    },
    {
      id: AppRoute.DiversificationAnalysis,
      label: ReportName.DiversificationAnalysis,
      isActive: permissions.ViewDiversificationAnalysisReport && isAlpInvestData,
    },
  ];

  if (carlyleReportingOptions.some((option) => option.isActive)) {
    result.push({
      id: "carlyle",
      label: "CARLYLE",
      options: carlyleReportingOptions,
    });
  }

  if (alpInvestReportingOptions.some((option) => option.isActive)) {
    result.push({
      id: "alpinvest",
      label: "CARLYLE ALPINVEST",
      options: alpInvestReportingOptions,
    });
  }

  return result;
};
