import { saveAs } from "file-saver";
import JSZip from "jszip";
import { map, uniq } from "lodash";
import { callGetDocumentDownloadUrl } from "@documents/store/thunks/callGetDocmentDownloadUrlApi";
import { callGetDownloadDocumentData } from "@documents/store/thunks/callGetDownloadDocumentDataAPI";
import { AppThunkAPI } from "@shared/models/redux";
import { DocumentFile, DocumentRow } from "@documents/models/dataGrid.models";
import { checkDocumentTerms } from "@terms/store/thunks/checkDocumentTerms";
import TermsNotAcceptedError from "@terms/utils/TermsNotAcceptedError";

type Document = Pick<DocumentRow, "id" | "fundId" | "title" | "fileType" | "documentSubtype" | "markedRead">;

type SuccessfullyArchivedDocumentFile = DocumentFile;
type DownloadAndArchiveDocuments = (
  documents: Document[],
  archiveName?: string
) => AppThunkAPI<Promise<SuccessfullyArchivedDocumentFile[]>>;

export const downloadAndArchiveDocuments: DownloadAndArchiveDocuments =
  (documents, archiveName = "documents") =>
  async (dispatch) => {
    const hasAcceptedTerms = await dispatch(
      checkDocumentTerms({
        documentSubTypes: uniq(map(documents, (document) => String(document.documentSubtype))),
        fundIds: uniq(map(documents, "fundId")),
      })
    );

    if (!hasAcceptedTerms) {
      throw new TermsNotAcceptedError();
    }

    const documentPromises = documents.map(async ({ id, title, fileType }) => {
      const url = await dispatch(callGetDocumentDownloadUrl(id));
      const data = await dispatch(callGetDownloadDocumentData(url));
      const name = title.replace(/[^A-Za-z0-9_ ]/g, "");
      const fileName = `${name}.${fileType}`;
      const content = data instanceof Blob ? data : data.blob;

      return content?.size ? { id, fileName, content } : { id, fileName, content: null };
    });

    const documentFiles = (await Promise.all(documentPromises)).filter(
      (file): file is DocumentFile => file.content !== null
    );

    const preparedDocumentFiles = documentFiles.reduce((result: Record<string, DocumentFile[]>, file) => {
      return {
        ...result,
        [file.fileName]: [...(result?.[file.fileName] ?? []), file],
      };
    }, {});

    const zip = new JSZip();

    Object.entries(preparedDocumentFiles).forEach(([fileName, files]) => {
      files.forEach(({ content }, index) => {
        const uniqueFileName = index === 0 ? fileName : fileName.split(".").join(`(${index}).`);

        zip.file(uniqueFileName, content);
      });
    });

    //by priyanka
    //saveAs(await zip.generateAsync({ type: "blob" }), archiveName);
    saveAs(await zip.generateAsync({ type: "blob" }), archiveName + ".zip");

    return documentFiles;
  };
