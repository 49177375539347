import React, { FunctionComponent, isValidElement, memo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { CircularProgress, Theme, Tooltip, Typography } from "@mui/material";
import { noop } from "lodash";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { ReactComponent as DownloadIcon } from "@assets/svg/download_icon.svg";
import { ReactComponent as ArrowBackIcon } from "@assets/svg/arrow_back.svg";
import { Container } from "@shared/components/Container";
import { ExportButtonConfig, PageProps } from "@shared/components/Page/models";
import { Button, RoundedIcon } from "@shared/components";

interface StyleProps {
  isEnabled: ExportButtonConfig["isEnabled"];
  withDrawer: PageProps["withDrawer"];
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    padding: theme.spacing(14, 0),
    [theme.breakpoints.down("lg")]: {
      padding: ({ withDrawer }) => (withDrawer ? theme.spacing(32.5, 0) : theme.spacing(24, 0)),
    },
  },
  backLink: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "uppercase",
    letterSpacing: 1.4,
    marginBottom: theme.spacing(10),
    color: theme.palette.primary.main,
    textDecoration: "none",
    transition: "color .15s",

    "&:hover": {
      textDecoration: "none",
      color: theme.palette.info.main,
    },
  },
  backLinkName: {
    marginLeft: theme.spacing(2),
  },
  titleWithControls: {
    fontSize: "50px",
    lineHeight: "initial",
    letterSpacing: 1,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular,
  },
  svgDownloadIcon: {
    display: "block",
    margin: theme.spacing(0, 6),
    width: 30,
    height: "100%",
    transition: "0.15s ease-out",
    cursor: ({ isEnabled }) => (isEnabled ? "pointer" : "not-allowed"),
    color: ({ isEnabled }) => (isEnabled ? theme.palette.primary.main : theme.palette.grey[500]),
    "&:hover": {
      color: ({ isEnabled }) => (isEnabled ? theme.palette.info.main : theme.palette.grey[500]),
    },
    [theme.breakpoints.down("sm")]: {
      width: 14,
      margin: 0,
    },
  },
  buttonsTool: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    minHeight: 80,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(8),
    rowGap: theme.spacing(8),
  },
  heading: {
    display: "flex",
    alignItems: "center",
    minHeight: 48,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& > button": {
      fontSize: 16,
      letterSpacing: 1.6,
      padding: theme.spacing(0, 7.5),
      marginTop: theme.spacing(6),
    },
  },
  loadingSpinnerWrapper: {
    margin: theme.spacing(0, 6),
    [theme.breakpoints.down("sm")]: {
      color: "white",
      margin: theme.spacing(0, 3),
    },
  },
}));

const Page: FunctionComponent<PageProps> = ({
  children,
  title,
  bottomAdornment,
  titleControl,
  exportButton,
  backLink,
  withDrawer,
}) => {
  const classes = useStyles({
    isEnabled: exportButton && "isEnabled" in exportButton && exportButton?.isEnabled,
    withDrawer,
  });
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Container className={classes.root}>
        <>
          {backLink && (
            <Link to={backLink.url} className={classes.backLink} data-testid="page-backlink">
              <ArrowBackIcon />
              <span className={classes.backLinkName}>{backLink.name}</span>
            </Link>
          )}
          {title && (
            <div className={classes.buttonsTool}>
              <div className={classes.heading}>
                <Typography variant="h3" data-testid={`page-title-${title.toLowerCase().replaceAll(" ", "-")}`}>
                  {title}
                </Typography>
                {exportButton &&
                  (isValidElement(exportButton)
                    ? exportButton
                    : "isEnabled" in exportButton && (
                        <>
                          {exportButton.text && isSmall ? (
                            <Button
                              onClick={exportButton.isEnabled ? exportButton.onClick : noop}
                              fullWidth
                              endIcon={
                                !exportButton.isDownloading ? (
                                  <RoundedIcon Icon={DownloadIcon} variant={"light"} />
                                ) : undefined
                              }
                            >
                              {exportButton.text}{" "}
                              {exportButton.isDownloading && (
                                <CircularProgress size={24} className={classes.loadingSpinnerWrapper} />
                              )}
                            </Button>
                          ) : exportButton.isDownloading ? (
                            <CircularProgress size={24} className={classes.loadingSpinnerWrapper} />
                          ) : (
                            <Tooltip
                              title={<Typography>{exportButton.hint ?? "Export as Excel"}</Typography>}
                              key="ExportAsExcel"
                              placement="top"
                            >
                              <DownloadIcon
                                className={classes.svgDownloadIcon}
                                onClick={exportButton.isEnabled ? exportButton.onClick : noop}
                                data-testid={`page-download-icon-${title.toLowerCase().replaceAll(" ", "-")}`}
                              />
                            </Tooltip>
                          )}
                        </>
                      ))}
              </div>
              <>{titleControl}</>
            </div>
          )}
          {children}
        </>
      </Container>
      {bottomAdornment}
    </>
  );
};

export default memo<PageProps>(Page) as typeof Page;
