import { useAppSelector } from "@app/hooks";
import { entitledFundsSelector } from "@store/Entities/selectors";

export const useAlpInvestEntitleDataValidaion = () => {
  const entitledFunds = useAppSelector(entitledFundsSelector);
  const alpnvestFunds = entitledFunds.data;

  if (!alpnvestFunds) return;
  const filteredEntries = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(alpnvestFunds).filter(([key, entities]) => {
      return entities.productFamily === "AlpInvest" && entities.productSegment === "Carlyle AlpInvest";
    })
  );

  return Object.keys(filteredEntries).length ? true : false;
};
