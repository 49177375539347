export enum SegmentTab {
  ViewAll = "viewAll",
  GlobalPrivateEquity = "globalPrivateEquity",
  GlobalCredit = "globalCredit",
  InvestmentSolutions = "investmentSolutions",
}
export const segmentTabHeaders: Record<SegmentTab, string> = {
  [SegmentTab.ViewAll]: "View All",
  [SegmentTab.GlobalPrivateEquity]: "Global Private Equity",
  [SegmentTab.GlobalCredit]: "Global Credit",
  [SegmentTab.InvestmentSolutions]: "Carlyle AlpInvest",
};

export const segmentTabValues: Record<SegmentTab, string> = {
  [SegmentTab.ViewAll]: "View All",
  [SegmentTab.GlobalPrivateEquity]: "Global Private Equity",
  [SegmentTab.GlobalCredit]: "Global Credit",
  [SegmentTab.InvestmentSolutions]: "Carlyle AlpInvest",
};
